import React from "react";
import { Col, Row } from "react-bootstrap";
import {
    DiJavascript1,
    DiNodejs,
    DiMongodb,
    DiGit,
    DiGo,
    DiReact
} from "react-icons/di";
import {
    SiRedis,
    SiExpress,
    SiFirebase,
    SiNuxtdotjs,
    SiSolidity,
    SiPostgresql,
    SiVuedotjs, SiMysql, SiPwa, SiFlutter, SiPhp, SiLaravel, SiNextdotjs
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiGo />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiExpress />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSolidity />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
         <SiVuedotjs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNuxtdotjs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
      </Col>
        <Col xs={4} md={2} className="tech-icons">
        <SiPwa />
      </Col>
        <Col xs={4} md={2} className="tech-icons">
        <SiFlutter />
      </Col>
        <Col xs={4} md={2} className="tech-icons">
        <SiPhp />
      </Col>
        <Col xs={4} md={2} className="tech-icons">
        <SiLaravel />
      </Col>
    </Row>
  );
}

export default Techstack;
