import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import rokla from "../../Assets/Projects/rokla.png";
import shiraztakhfif from "../../Assets/Projects/shiraztakhfif.png";
import mydgn from "../../Assets/Projects/mydgn.png";
import whatsgaming from "../../Assets/Projects/whatsgaming.png";
import univly from "../../Assets/Projects/univly.png";
import longlifestem from "../../Assets/Projects/longlifestem.png";
import grilli from "../../Assets/Projects/grilli.png";
import imofix from "../../Assets/Projects/imofix.png";
import outschool from "../../Assets/Projects/outschool.png";
import ecommerce from "../../Assets/Projects/ecommerce.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={outschool}
              isBlog={false}
              title="Outschool"
              description="An LMS website serves as a centralized hub for all educational content, including videos, courses, workshops, and documents build with Laravel as backend restAPI and Next.js"
              demoLink="https://outschool.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={whatsgaming}
              isBlog={false}
              title="WhatsGaming"
              description="Betting online website platform where individuals can place bets on various football events and games build with Laravel and Nuxt.js with bootstrap framework"
              demoLink="https://whatsgaming.net"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={univly}
              isBlog={false}
              title="Univly"
              description="Search and apply for universities around the world that fully build with Laravel framework"
              demoLink="https://univly.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imofix}
              isBlog={false}
              title="Imofix"
              description="A demo template of home cleaning and services build with Nuxt.js and Vuetify.js framework"
              demoLink="https://imofix.alichv.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={longlifestem}
              isBlog={false}
              title="Longlifestem"
              description="A multilingual portfolio website build with Laravel and Bootsrap framework"
              demoLink="https://longlifestem.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mydgn}
              isBlog={false}
              title="Mydgn"
              description="A marketplace to buy and sell coins earned from whatsgaming bet platfrom build with Laravel and Vue.js using Bootstrap framework"
              demoLink="https://www.mydgn.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={grilli}
              isBlog={false}
              title="Food"
              description="A food website that describe a restaurant and it's own online menu build with HTML CSS using Bootstrap framework and support PWA"
              demoLink="https://grilli.alichv.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rokla}
              isBlog={false}
              title="Rokla"
              description="An online contract platform that facilitates the creation, management, and execution of contracts between parties build with Node.js and Nuxt.js using Tailwind framework and support PWA"
              demoLink="https://www.rokla.ir/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={shiraztakhfif}
              isBlog={false}
              title="ShirazTakhfif"
              description="An online discount coupon website that aggregates and offers a variety of promotional codes, vouchers, and coupons that consumers can use to obtain discounts on products or services build with Laravel and Nuxt.js using bootstrap framework"
              demoLink="https://www.shiraztakhfif.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ecommerce}
              isBlog={false}
              title="Ecommerce"
              description="An online e-commerce website that allows businesses to sell products and services directly to consumers over the internet build with Laravel using Bootstrap framework"
              demoLink="https://ecommerce.alichv.com/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
